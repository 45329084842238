import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorReadyFn, FlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { openShowHidePanel } from './components/showHidePanel/showHideActions';
import {
  desktopPresets,
  mobilePresets,
  presets,
} from './assets/presets/presetsIds';
import { counterDesignPresetsMobile } from './assets/presets/counterDesignPresetsMobile';
import moment from 'moment-timezone';
import { sendBi } from './components/Countdown/helper';
import {
  COUNTDOWN_CLOCK_STATE,
  END_MESSAGE_STATE,
} from './components/constants';
import { AddedProperties } from './components/Types';
import { counterDesignPresetsDesktop } from './assets/presets/counterDesignPresetsDesktop';
import { timerProps } from './components/Timer1/timerProps';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const changeMobilePreset = (presetId: string) => {
    switch (presetId) {
      case desktopPresets[0].id: {
        return mobilePresets[0].id;
      }
      case desktopPresets[3].id: {
        return mobilePresets[1].id;
      }
      case desktopPresets[1].id: {
        return mobilePresets[2].id;
      }
      case desktopPresets[2].id: {
        return mobilePresets[1].id;
      }
      case desktopPresets[4].id: {
        return mobilePresets[2].id;
      }
      default:
        return presetId;
    }
  };
  const updateCounterWidgetPropsAfterReset = (widgetProps: any) => {
    const updatedProps: any = {};
    if (widgetProps?.daysLabel !== undefined) {
      updatedProps.daysLabel = widgetProps?.daysLabel;
    }
    if (widgetProps?.hoursLabel !== undefined) {
      updatedProps.hoursLabel = widgetProps?.hoursLabel;
    }
    if (widgetProps?.minutesLabel !== undefined) {
      updatedProps.minutesLabel = widgetProps?.minutesLabel;
    }
    if (widgetProps?.secondsLabel !== undefined) {
      updatedProps.secondsLabel = widgetProps?.secondsLabel;
    }
    if (widgetProps?.showLabels !== undefined) {
      updatedProps.showLabels = widgetProps?.showLabels;
    }
    if (widgetProps?.animationType !== undefined) {
      updatedProps.animationType = widgetProps?.animationType;
    }
    if (widgetProps?.showSeparators !== undefined) {
      updatedProps.showSeparators = widgetProps?.showSeparators;
    }
    return updatedProps;
  };
  const presetIdsWithoutSeparators = [
    desktopPresets[3].id,
    desktopPresets[2].id,
    mobilePresets[1].id,
  ];
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
    essentials: options.essentials,
  });
  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      const { preset, componentRef } = event.detail;
      try {
        const allAncestorsRefs = await editorSDK.components.getAncestors('', {
          componentRef,
        });
        const widgetProps =
          await editorSDK.application.appStudioWidgets.props.get('token', {
            widgetRef: componentRef,
          });
        const updatedWidgetProps =
          updateCounterWidgetPropsAfterReset(widgetProps);
        await editorSDK.components.refComponents.removeAllOverrides('', {
          componentRef: allAncestorsRefs[0],
        });
        await editorSDK.application.appStudioWidgets.changePreset('', {
          context: { viewport: 'MOBILE' },
          componentRef: allAncestorsRefs[0],
          stylePresetId: changeMobilePreset(preset),
          layoutPresetId: changeMobilePreset(preset),
        });

        const formFactor = await editorSDK.editor.info.getEditorMode('');
        const hasSeparators = !presetIdsWithoutSeparators.includes(preset);
        await editorSDK.application.appStudioWidgets.props.set('token', {
          widgetRef: componentRef,
          newProps: {
            ...updatedWidgetProps,
            showSeparators:
              formFactor === 'mobile'
                ? updatedWidgetProps.showSeparators
                : hasSeparators,
          },
        });

        let countdownProps;
        await asyncEvery(allAncestorsRefs, async (currentRef) => {
          try {
            const props =
              await editorSDK.application.appStudioWidgets.props.get('token', {
                widgetRef: currentRef,
              });
            if (props !== undefined) {
              countdownProps = props;
              editorSDK.application.appStudioWidgets.props.set('token', {
                widgetRef: currentRef,
                newProps: {
                  ...countdownProps,
                  daysShowInElementsPanel_countdown: true,
                  hoursShowInElementsPanel_countdown: true,
                  minutesShowInElementsPanel_countdown: true,
                  secondsShowInElementsPanel_countdown: true,
                },
              });
              return false;
            }
          } catch (e) {
            return true;
          }
          return true;
        });

        await editorSDK.document.application.livePreview.refresh('', {
          shouldFetchData: true,
          source: '',
        });
      } catch (e) {
        console.log({ e });
      }
    },
  );
  await editorSDK.addEventListener('switchedFromPreview', async (event) => {
    if (flowAPI.environment.isClassicEditor) {
      await resetStateAndTabToDefault();
    }
  });
  await editorSDK.addEventListener('switchedToMobileView', async (event) => {
    if (flowAPI.environment.isClassicEditor) {
      await resetStateAndTabToDefault();
    }
  });
  await editorSDK.addEventListener('switchedToDesktopView', async (event) => {
    if (flowAPI.environment.isClassicEditor) {
      await resetStateAndTabToDefault();
    }
  });
  await editorSDK.addEventListener('componentDeleted', async (event) => {
    const eventDetail = event?.detail;
    const connections =
      eventDetail && eventDetail?.connections?.length
        ? eventDetail.connections[0]
        : undefined;

    if (connections !== undefined) {
      const { role, controllerRef } = connections;
      const unitsRoles = [
        'days',
        // 'seperator1',
        'hours',
        // 'seperator2',
        'minutes',
        // 'seperator3',
        'seconds',
      ];

      if (unitsRoles.includes(role)) {
        const unitDataAfterDelete = await getUnitsDataAfterDelete(
          controllerRef,
        );
        if (unitDataAfterDelete) {
          const notCollapsedItems = unitDataAfterDelete.filter((data) => {
            return !data.isCollapsed;
          });
          const isHeadingSeparator =
            notCollapsedItems.length > 0
              ? !notCollapsedItems[0].isDigits
              : false;

          const isTailingSeparator =
            notCollapsedItems.length > 0
              ? !notCollapsedItems[notCollapsedItems.length - 1].isDigits
              : false;

          if (isHeadingSeparator) {
            collapseElementByRole(notCollapsedItems[0].role, controllerRef);
          }
          if (isTailingSeparator) {
            collapseElementByRole(
              notCollapsedItems[notCollapsedItems.length - 1].role,
              controllerRef,
            );
          }
          notCollapsedItems.forEach((unit, index) => {
            if (index < notCollapsedItems.length - 1) {
              if (!unit.isDigits && !notCollapsedItems[index + 1].isDigits) {
                collapseElementByRole(
                  notCollapsedItems[index].role,
                  controllerRef,
                );
              }
            }
          });
        }
      }
    }
  });

  const asyncEvery = async (arr, predicate) => {
    for (const e of arr) {
      try {
        if (!(await predicate(e))) {
          return false;
        }
      } catch (e) {
        return true;
      }
    }
    return true;
  };
  const getUnitsDataAfterDelete = async (controllerRef) => {
    const unitsRoles = [
      'days',
      'seperator1',
      'hours',
      'seperator2',
      'minutes',
      'seperator3',
      'seconds',
    ];
    const sepRoles = ['seperator1', 'seperator2', 'seperator3'];
    const unitsDataAfterDelete: any = [];
    for (const unitRole of unitsRoles) {
      const refRole = await editorSDK.document.components.findAllByRole(
        'token',
        {
          controllerRef,
          role: unitRole,
        },
      );
      const isReferredCompCollapsed =
        await editorSDK.document.components.refComponents.isRefComponentCollapsed(
          'token',
          { componentRef: refRole[0] },
        );
      unitsDataAfterDelete.push({
        role: unitRole,
        isCollapsed: isReferredCompCollapsed,
        isDigits: !sepRoles.includes(unitRole),
      });
    }
    return unitsDataAfterDelete;
  };

  const collapseElementByRole = async (role: string, controllerRef) => {
    const customButtons = await editorSDK.document.components.findAllByRole(
      'token',
      {
        controllerRef,
        role,
      },
    );
    if (customButtons.length) {
      editorSDK.components.refComponents.collapseReferredComponent('token', {
        componentRef: customButtons[0],
      });
    }
  };

  const setDefaultDatePickerValue = async (widgetRef) => {
    const defaultDay = new Date();
    const numberOfDaysToAdd = 3;
    defaultDay.setDate(defaultDay.getDate() + numberOfDaysToAdd);
    defaultDay.setHours(0, 0);
    const [componentWidgetRef] = await editorSDK.components.getChildren('', {
      componentRef: widgetRef,
    });
    editorSDK.document.components
      .findAllByRole('token', {
        controllerRef: componentWidgetRef,
        role: 'button1',
      })
      .then(async (customButtons) => {
        await editorSDK.components.data.update('token', {
          componentRef: customButtons[0],
          data: {
            label: flowAPI.translations.t('app.countdown.buyNow.button'),
          },
        });
      });
    editorSDK.document.components
      .findAllByRole('token', {
        controllerRef: componentWidgetRef,
        role: 'button2',
      })
      .then(async (customButtons) => {
        await editorSDK.components.data.update('token', {
          componentRef: customButtons[0],
          data: {
            label: flowAPI.translations.t('app.countdown.endMessage.button'),
          },
        });
      });

    editorSDK.application.appStudioWidgets.props
      .set('token', {
        widgetRef: componentWidgetRef,
        newProps: {
          no_repeat_endDateTimer: defaultDay.toString(),
        },
      })
      .catch((e) => {
        console.log('error in default date', e);
      });
  };

  const resetStateAndTabToDefault = async () => {
    try {
      const allAppRefComponents =
        await editorSDK.document.components.refComponents.getAllAppRefComponents(
          'token',
        );
      if (allAppRefComponents && allAppRefComponents.length) {
        for (const ref of allAppRefComponents) {
          const [componentWidgetRef] = await editorSDK.components.getChildren(
            '',
            {
              componentRef: ref,
            },
          );
          if (componentWidgetRef) {
            await editorSDK.application.appStudioWidgets.props.set('token', {
              widgetRef: componentWidgetRef,
              newProps: {
                currentTabValue: 'Tab 1',
              },
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  resetStateAndTabToDefault();
  await editorSDK.addEventListener('viewStateChanged', async (event) => {
    const { stateName } = event.detail;
    const allAppRefComponents =
      await editorSDK.document.components.refComponents.getAllAppRefComponents(
        'token',
      );
    if (allAppRefComponents && allAppRefComponents.length) {
      for (const item of allAppRefComponents) {
        try {
          const [componentWidgetRef] = await editorSDK.components.getChildren(
            '',
            {
              componentRef: item,
            },
          );
          if (componentWidgetRef) {
            await editorSDK.application.appStudioWidgets.props.set('token', {
              widgetRef: componentWidgetRef,
              newProps: {
                currentTabValue:
                  stateName === COUNTDOWN_CLOCK_STATE ? 'Tab 1' : 'Tab 2',
              },
            });
          }
        } catch (e) {
          console.log({ e });
        }
      }
    }
  });
  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case 'editTitleText': {
        await panelsApi.openBlocksPanel('Edit Text', componentRef);
        break;
      }
      case 'editTextEndMessage': {
        await panelsApi.openBlocksPanel(
          'Edit Text - end message',
          componentRef,
        );
        break;
      }
    }
  });
  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case 'timerSettings': {
        await panelsApi.openBlocksPanel('Timer Settings', componentRef, {
          overrides: {
            title: flowAPI.translations.t('app.timer.settings.panel.title'),
          },
        });
        break;
      }
      case 'countDownSettings': {
        const defaultTimeZone = moment.tz.guess();
        editorSDK.application.appStudioWidgets.props.set('token', {
          widgetRef: componentRef,
          newProps: {
            defaultTimeZone,
          },
        });
        await panelsApi.openBlocksPanel(
          'Countdown Clock Settings',
          componentRef,
          {
            overrides: {
              title: flowAPI.translations.t(
                'app.countdown.settings.panel.title',
              ),
            },
          },
        );
        break;
      }
      case 'openShowHideElementsPanel': {
        const t: TFunction = flowAPI.translations.t as TFunction;
        const widgetProps =
          await editorSDK.application.appStudioWidgets.props.get('token', {
            widgetRef: componentRef,
          });
        const currentTab =
          widgetProps && widgetProps.currentTabValue
            ? widgetProps.currentTabValue
            : 'Tab 1';
        const timerWidgetRef =
          await editorSDK.document.components.findAllByRole('token', {
            controllerRef: componentRef,
            role: 'timer1',
          });
        void (await openShowHidePanel(
          editorSDK,
          componentRef,
          t,
          currentTab,
          timerWidgetRef,
        ));
        break;
      }
      case 'countdownLayout': {
        const timerWidgetProps = await getTimerWidgetProps(componentRef);
        const selectedPreset = await getSelectedPreset(componentRef);
        const formFactor = await editorSDK.editor.info.getEditorMode('');
        const { showSeparators } = timerWidgetProps;
        editorSDK.application.appStudioWidgets.props.set('token', {
          widgetRef: componentRef,
          newProps: {
            formFactor,
            selectedPreset,
            isSeparatorsCollapsed:
              showSeparators !== undefined
                ? !showSeparators
                : !timerProps.showSeparators.defaultValue,
          },
        });
        await panelsApi.openBlocksPanel('Countdown Clock Layout', componentRef);
        break;
      }
      default:
    }
  });
  const getSelectedPreset = async (componentRef) => {
    try {
      const currentState = await editorSDK.editor.widgets.getViewState(
        'token',
        {
          componentRef,
        },
      );
      if (currentState !== END_MESSAGE_STATE) {
        const [refRole] = await editorSDK.document.components.findAllByRole(
          'token',
          {
            controllerRef: componentRef,
            role: 'timer1',
          },
        );
        const selectedPreset =
          await editorSDK.application.appStudioWidgets.getPreset('', {
            componentRef: refRole,
          });
        return selectedPreset;
      } else {
        return null;
      }
    } catch (e) {
      console.log('error ', e);
      return desktopPresets[0].id;
    }
  };
  if (options.firstInstall) {
    const widgetRef = await editorSDK.application.appStudioWidgets.addWidget(
      '',
      {
        widgetId: '867ffe30-6230-4bcc-a1f2-517577df5cc6',
        installationType: 'closed' as WidgetInstallationType,
        scopedPresets: {
          desktop: {
            layout: presets[0].id,
            style: presets[0].id,
          },
          mobile: {
            layout: presets[1].id,
            style: presets[1].id,
          },
        },
        layout: {
          height: 393,
          width: 980,
          x: 0,
          y: 100,
        } as any,
        layouts: {
          componentLayout: {
            id: presets[0].id,
            minHeight: {
              type: 'px',
              value: 100,
            },
            hidden: false,
            height: {
              type: 'auto',
            },
            type: 'ComponentLayout',
            width: {
              type: 'percentage',
              value: 100,
            },
          },
          itemLayout: {
            id: presets[0].id,
            alignSelf: 'start',
            margins: {
              left: {
                type: 'px',
                value: 0,
              },
              right: {
                type: 'px',
                value: 0,
              },
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 1,
              rowEnd: 2,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'start',
            type: 'GridItemLayout',
          },
          containerLayout: {
            id: '',
            type: 'GridContainerLayout',
            rows: [
              {
                type: 'fr',
                value: 1,
              },
            ],
            columns: [
              {
                type: 'fr',
                value: 1,
              },
            ],
          },
        } as any,
      },
    );
    if (widgetRef) {
      await setDefaultDatePickerValue(widgetRef);
    }
  }

  await editorSDK.addEventListener('siteWasPublished', async (event) => {
    const allAppRefComponents =
      await editorSDK.document.components.refComponents.getAllAppRefComponents(
        'token',
      );
    const [componentWidgetRef] = await editorSDK.components.getChildren('', {
      componentRef: allAppRefComponents[0],
    });
    const widgetProps = editorSDK.application.appStudioWidgets.props.get(
      'token',
      {
        widgetRef: componentWidgetRef,
      },
    );
    const timerWidgetProps = getTimerWidgetProps(componentWidgetRef);
    const timerSelectedPreset = getSelectedPreset(componentWidgetRef);
    const deviceType = editorSDK.editor.info.getEditorMode('');
    Promise.all([
      timerWidgetProps,
      widgetProps,
      timerSelectedPreset,
      deviceType,
    ])
      .then(async (values) => {
        const biEventsParams: AddedProperties = {
          ...values[0],
          ...values[1],
          timerSelectedPreset: values[2],
          deviceType: values[3],
        };
        await sendBi({ biEventsParams, editorSDK });
      })
      .catch((error) => {
        console.log(error);
      });
  });
  const getTimerWidgetProps = async (componentRef) => {
    const [refRole] = await editorSDK.document.components.findAllByRole(
      'token',
      {
        controllerRef: componentRef,
        role: 'timer1',
      },
    );
    const widgetProps = await editorSDK.application.appStudioWidgets.props.get(
      'token',
      {
        widgetRef: refRole,
      },
    );
    return widgetProps;
  };
};

export const getAppManifest = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const { subType } = contextParams?.origin;
  const { t } = flowAPI.translations;
  const baseManifest: any = await fetchWidgetsStageData(options);
  const isStudioEditor: boolean = subType === 'STUDIO';
  const counterControllerId = 'b942197b-fe14-4565-96f3-41adb6054394-dkput';
  const countdownControllerId = 'b942197b-fe14-4565-96f3-41adb6054394-z7lno';

  appManifestBuilder.configureWidget(countdownControllerId, (widgetBuilder) => {
    widgetBuilder.configureWidgetViewState(
      COUNTDOWN_CLOCK_STATE,
      (widgetStateBuilder) => {
        widgetStateBuilder.title('Countdown Clock');
      },
    );
    widgetBuilder.configureWidgetViewState(
      END_MESSAGE_STATE,
      (widgetStateBuilder) => {
        widgetStateBuilder.title('End Message');
      },
    );
    widgetBuilder.behavior().set({
      closed: { selectable: true },
    });
    widgetBuilder.gfpp().set('stretch', {
      behavior: 'HIDE',
    });
    widgetBuilder.gfpp('mobile').set('mainAction1', {
      behavior: 'HIDE',
    });
    widgetBuilder.gfpp().set('help', {
      id: 'c327f46d-251e-4381-ba59-118d6dd9173b',
    });
  });
  appManifestBuilder.configureWidget(counterControllerId, (widgetBuilder) => {
    widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
      widgetDesignBuilder.setPresets(
        counterDesignPresetsDesktop(isStudioEditor) as any,
      );
    });
  });

  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();
  const widgetDesignDataInDesktop =
    manifest.controllersStageData[counterControllerId].default.gfpp.desktop
      .widgetDesign;

  manifest.controllersStageData[
    counterControllerId
  ].default.gfpp.mobile.widgetDesign = {
    ...widgetDesignDataInDesktop,
    presets: counterDesignPresetsMobile(isStudioEditor),
  };

  manifest.controllersStageData[
    countdownControllerId
  ].default.gfpp.mobile.iconButtons.add = { behavior: 'HIDE' };

  manifest.controllersStageData[
    countdownControllerId
  ].default.connections.timer1 = {};
  return manifest;
};

export const exports = (editorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      return (
        await editorSDK.application.appStudioWidgets.getPreset('', {
          componentRef: widgetRef,
        })
      ).style;
    },
  },
});
