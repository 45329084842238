import defaultPreset from '../presets-thumbnails/default.png';
import labelTopPreset from '../presets-thumbnails/label-top.png';
import labelInlinePreset from '../presets-thumbnails/label-inline.png';
import labelBottomPreset from '../presets-thumbnails/label-bottom.png';
import digitsCounterPreset from '../presets-thumbnails/digits.png';
import defaultPresetStudio from '../presets-thumbnails/default-studio.png';
import labelTopPresetStudio from '../presets-thumbnails/label-top-studio.png';
import labelInlinePresetStudio from '../presets-thumbnails/label-inline-studio.png';
import labelBottomPresetStudio from '../presets-thumbnails/label-bottom-studio.png';
import digitsPresetStudio from '../presets-thumbnails/digits-studio.png';
import { desktopPresets } from './presetsIds';

export const counterDesignPresetsDesktop = (isEditorX: boolean) => [
  {
    id: desktopPresets[0].id,
    src: isEditorX ? defaultPresetStudio : defaultPreset,
    layout: {
      width: '90%',
      height: '100%',
    },
  },
  {
    id: desktopPresets[4].id,
    src: isEditorX ? digitsPresetStudio : digitsCounterPreset,
    layout: {
      width: '90%',
      height: '100%',
    },
  },
  {
    id: desktopPresets[2].id,
    src: isEditorX ? labelInlinePresetStudio : labelInlinePreset,
    layout: {
      width: '90%',
      height: '100%',
    },
  },
  {
    id: desktopPresets[1].id,
    src: isEditorX ? labelBottomPresetStudio : labelBottomPreset,
    layout: {
      width: '90%',
      height: '100%',
    },
  },
  {
    id: desktopPresets[3].id,
    src: isEditorX ? labelTopPresetStudio : labelTopPreset,
    layout: {
      width: '90%',
      height: '100%',
    },
  },
];
