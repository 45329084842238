const desktopPresets: any = [
  { name: 'default', id: 'variants-ljb6xffn' },
  { name: 'label bottom', id: 'variants-ljb6xffo2' },
  { name: 'label inline', id: 'variants-ljb6xffo1' },
  { name: 'label top', id: 'variants-ljb6xffo' },
  { name: 'digits repeater', id: 'variants-ljb6xffo3' },
];
const mobilePresets: any = [
  { name: 'default mobile', id: 'variants-ljb6xffo4' },
  { name: 'label top mobile', id: 'variants-ljb6xffp1' },
  { name: 'label bottom mobile', id: 'variants-ljb6xffp' },
];
const presets = [
  { name: 'Box Layout', id: 'variants-l4dx7lmw' },
  { name: 'Mobile', id: 'variants-l9wnigiz' },
  { name: 'Strip Layout', id: 'variants-l8bd2u28' },
];
export { desktopPresets, mobilePresets, presets };
