import defaultPreset from '../presets-thumbnails/default.png';
import labelTopPreset from '../presets-thumbnails/label-top.png';
import labelBottomPreset from '../presets-thumbnails/label-bottom.png';
import defaultPresetStudio from '../presets-thumbnails/default-studio.png';
import labelTopPresetStudio from '../presets-thumbnails/label-top-studio.png';
import labelBottomPresetStudio from '../presets-thumbnails/label-bottom-studio.png';
import { mobilePresets } from './presetsIds';

export const counterDesignPresetsMobile = (isEditorX: boolean) => [
  {
    id: mobilePresets[0].id,
    src: isEditorX ? defaultPresetStudio : defaultPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: mobilePresets[1].id,
    src: isEditorX ? labelTopPresetStudio : labelTopPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: mobilePresets[2].id,
    src: isEditorX ? labelBottomPresetStudio : labelBottomPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
];
